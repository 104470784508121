import {
  compareVersions,
  LocalParticipant,
  Participant,
  Room,
  TranscriptionSegment,
} from 'livekit-client';
import {
  LegacyReceivedChatMessage,
  ReceivedChatMessage,
} from '../model/LegacyReceivedChatMessage';
import { ChatMessageType } from '../model/ChatMessageType';

export const normalizeFrequencies = (frequencies: Float32Array) => {
  const normalizeDb = (value: number) => {
    const minDb = -100;
    const maxDb = -10;
    let db = 1 - (Math.max(minDb, Math.min(maxDb, value)) * -1) / 100;
    db = Math.sqrt(db);

    return db;
  };

  // Normalize all frequency values
  return frequencies.map((value) => {
    if (value === -Infinity) {
      return 0;
    }
    return normalizeDb(value);
  });
};

const encoder = new TextEncoder();
const decoder = new TextDecoder();

export const encode = (message: LegacyReceivedChatMessage) =>
  encoder.encode(JSON.stringify(message));

export const decode = (message: Uint8Array) =>
  JSON.parse(decoder.decode(message)) as
    | LegacyReceivedChatMessage
    | ReceivedChatMessage;

export const serverSupportsChatApi = (room: Room) =>
  room.serverInfo?.edition === 1 ||
  (!!room.serverInfo?.version &&
    compareVersions(room.serverInfo?.version, '1.17.2') > 0);

export const segmentToChatMessage = (
  s: TranscriptionSegment,
  existingMessage: ChatMessageType | undefined,
  participant: Participant
): ChatMessageType => {
  const msg: ChatMessageType = {
    id: s.id,
    message: s.final ? s.text : `${s.text} ...`,
    name: participant instanceof LocalParticipant ? 'You' : 'Agent',
    isSelf: participant instanceof LocalParticipant,
    timestamp: existingMessage?.timestamp ?? Date.now(),
  };
  return msg;
};
