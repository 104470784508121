<div *ngIf="trackPublications.length > 0; else noTracks">
  <div *ngFor="let tp of trackPublications">
    <app-km-participant-box
      [room]="room"
      [participant]="participant"
      [trackPublication]="tp"
      *ngIf="
        participant?.sid != mainVideo?.participant?.sid ||
        (mainVideo?.track &&
          participant?.sid === mainVideo?.participant?.sid &&
          tp?.source != mainVideo?.track?.source)
      "
      [audioTrackPublication]="participantAudioTrack"
    ></app-km-participant-box>
  </div>
</div>
<ng-template #noTracks>
  <app-km-participant-box
    *ngIf="participant?.sid != mainVideo?.participant?.sid"
    [room]="room"
    [participant]="participant"
    [audioTrackPublication]="participantAudioTrack"
  ></app-km-participant-box>
</ng-template>
