<h2 class="text-danger">Network Issue</h2>
<p>
  Your network connection is not stable. Please follow the steps below to solve
  the issue.
</p>
<ol>
  <li>Refresh the page and try again</li>
  <li>Reconnect your network</li>
  <li>
    If you are using wifi connection then turn off your wifi for 10 sec and try
    again.
  </li>
</ol>
<div class="d-flex justify-content-end">
  <button
    type="submit"
    (click)="onClickUnderstoodButton()"
    class="btn btn-primary"
  >
    Ok, I Understood
  </button>
</div>
