/*******************************************************************************
 * Copyright (C) Cynnox, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential Written by Arun Girivasan <arun@kollegenet.com>, June 2019
 ******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChatMessage,
  ConnectionQuality,
  ConnectionState,
  DataPacket_Kind,
  DisconnectReason,
  LocalParticipant,
  LocalTrackPublication,
  MediaDeviceFailure,
  Participant,
  ParticipantKind,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  RpcInvocationData,
  Track,
  TrackPublication,
  TranscriptionSegment,
  VideoPresets,
} from 'livekit-client';
import { Subscription } from 'rxjs';
import { ActiveCallsWebsocket } from 'src/app/model/Calls';
import { LivekitAccessTokenRes } from 'src/app/model/LivekitAccessTokenRes';
import { WebsocketService } from 'src/app/services/websocket.service';
import {
  decode,
  encode,
  normalizeFrequencies,
  segmentToChatMessage,
  serverSupportsChatApi,
} from '../../helper/livekit.helper';
import { CallStatus } from '../../model/CallStatus';
import { ChatBoxOptions } from '../../model/ChatBoxOptions';
import { ChatMessageType } from '../../model/ChatMessageType';
import { BroadcastConfig } from '../../model/model';
import { KayoolMeetService } from '../../service/kayool-meet.service';
import { LivekitChatService } from '../../service/livekit-chat.service';
import { VideoBroadcastService } from '../../service/video-broadcast.service';
import { KmeetDeviceSettingsComponent } from '../kmeet-device-settings/kmeet-device-settings.component';
import { StopAlertComponent } from '../stop-alert/stop-alert.component';
import { SCREEN_VIEWS } from './SCREEN_VIEWS';
import { ExitSessionInformationComponent } from '../exit-session-information/exit-session-information.component';

@Component({
  selector: 'broadcaster',
  templateUrl: './broadcaster.component.html',
  styleUrls: ['./broadcaster.component.scss'],
})
export class BroadcasterComponent implements OnInit, OnDestroy {
  startVideoCall: boolean = false;
  connection: any;
  conference: any;
  isJoined = false;

  screenSharing = false;
  connectingWithManager = false;
  roomName: string = '';
  avatarUrl: string = '';
  kollegenetUserID: number = 0;
  firstName: string = '';
  lastName: string = '';
  scrWidth: any;
  changeMousePoint: boolean = false;
  myJitsiID: string = '';
  exitSession: boolean = true;
  cameraVideoTrack: any = null;
  desktopVideoTrack: any = null;
  audioTrack: any = null;
  desktopAudioTrack: any = null;
  // desktopVideoStream: any = null;
  localPresenterVideoTrack: any = null;
  // presenterEffect: JitsiStreamPresenterEffect = null;
  cameraProcessing: boolean = false;
  micProcessing: boolean = false;
  screenShareProcessing: boolean = false;
  disconnected: boolean = false;
  // liveClassHosts: liveClassHosts[] = null;
  mainVideoProps: any = {};
  wait_for_host_timer: string = '';
  INTERVAL_TIMEOUT = 3;
  rejoinAsBroadcaster: boolean = false;
  //this variable true means live class is started by this user
  iamBroadcaster: boolean = true;
  showAlwaysOnTopUserView: boolean = true;
  showAlwaysOnTopParticipantView: boolean = true;
  alwaysOnTopRender: any = null;
  screenSharingRender: any = null;
  maxNoOfParticipantsPopupView: number = 0;
  hostChangeLoader: any = {};
  previous_view: SCREEN_VIEWS = SCREEN_VIEWS.MAIN_VIEW;

  controllsTimeout: any = null;
  showNotAvailableDialog: boolean = false;
  liveStreamRecording: boolean = false;
  liveStreamRecordDuration: string = '';
  navigatedView: string = '';
  showFooterControls: boolean = true;
  popupViewParticipantsHeight: number = 0;
  p2pEnabled: boolean = true;
  startWithVideoMuted: boolean = false;

  callConnected: boolean = false;

  @ViewChild('ringer') ringer: ElementRef = null;
  ringing: boolean = false;
  chatboxOptions: ChatBoxOptions = { show: false, showHeader: false };

  private broadcastListener: Subscription;
  private stopBroadcastActionListener: Subscription;
  private broadCastWebsocketLister: Subscription;
  private timerListener: Subscription;
  private exitBroadcastActionListener: Subscription;
  private websocketConnectionListener: Subscription;
  private customerCallsSubscription: Subscription;

  @Output() onBroadcast = new EventEmitter<boolean>();
  _interval = null;
  _count = 60;
  timer: any;

  // livekit variables
  mounted: boolean = false;
  chatMessageInput: string = '';
  token: string = '';
  serverURL: string = '';
  // creates a new room with options
  room: Room = null;
  agent: Participant = null;
  interval: any;
  audioStream: MediaStream;
  mediaRecorder: MediaRecorder;
  audioContext: AudioContext;
  analyser: AnalyserNode;
  source: MediaStreamAudioSourceNode;
  audioChunks: any = []; // To store recorded audio chunks
  // dataArray: Uint8Array;
  // progressbar 2
  voiceBarProgress: Array<string>;
  startTime: number;
  participants: Participant[] = [];
  mainVideoParticipant: Participant;
  mainVideoTrack: Track;
  showCallForwardingMessage: boolean = false;
  chatBoxButtonSubscription: Subscription;
  localMessageSubscription: Subscription;
  config: BroadcastConfig;

  constructor(
    public broadcastService: VideoBroadcastService,
    private dialog: MatDialog,
    private kayoolMeetService: KayoolMeetService,
    private websocketService: WebsocketService,
    private livekitChatService: LivekitChatService
  ) {}

  ngOnInit(): void {
    this.mounted = true;
    this.exitSession = true;

    // wait for host timer listener
    this.timerListener = this.broadcastService
      .gettimerObservable()
      .subscribe((res) => {
        if (res) {
          this.settimer(2);
        }
      });

    //broadcast start listener
    this.broadcastListener = this.broadcastService
      .getBroadcastistener()
      .subscribe((config: BroadcastConfig) => {
        this.onStartCallRequestReceived(config);
      });

    //stop broadcast started by this user - listener
    this.stopBroadcastActionListener = this.broadcastService
      .getStopBroadcastActionListener()
      .subscribe((stop) => {
        console.log('getStopBroadcastActionListener()');
        this.stopBroadcast(false);
      });

    //exit from broadcast joined - listener
    this.exitBroadcastActionListener = this.broadcastService
      .getStopBroadcastWatchActionListener()
      .subscribe((stop) => {
        console.log('getStopBroadcastWatchActionListener()');
        this.exitSession = true;
        this.stopBroadcast(true);
      });

    this.getScreenSize();

    this.customerCallsSubscription = this.websocketService
      .getCustomerCallObservable()
      .subscribe((res: ActiveCallsWebsocket) => {
        if (res.delete) {
          this.stopRinger(true);
          this.showNotAvailableDialog = res?.call?.acceptedTime == null;
          this.callConnected = false;
        } else {
          if (this.callConnected) {
            console.log(
              'CALL IS FORWARDING TO OTHER DEPARTMENT --- SHOW RINGER'
            );
            // this.startRing();
            const toastLiveExample = document.getElementById('liveToast');
            if (toastLiveExample) {
              toastLiveExample.style.display = 'block';
            }
            this.callConnected = false;
          }
        }
      });

    this.chatBoxButtonSubscription = this.livekitChatService
      .getChatBoxPreviewObservable()
      .subscribe((options: ChatBoxOptions) => {
        if (!options.show && this.chatboxOptions.show && !this.config.call) {
          // user is using chat only and chat box closed then stop the connection
          this.stopBroadcastBtnClick();
        }
        this.chatboxOptions = options;
      });

    this.localMessageSubscription = this.livekitChatService
      .getLocalMessageObservables()
      .subscribe((msg: string) => {
        this.sendLocalMessage(msg);
      });
  }

  ngOnDestroy(): void {
    this.mounted = false;

    if (this.broadcastListener) {
      this.broadcastListener.unsubscribe();
    }
    if (this.timerListener) {
      this.timerListener.unsubscribe();
    }
    if (this.stopBroadcastActionListener) {
      this.stopBroadcastActionListener.unsubscribe();
    }
    if (this.broadCastWebsocketLister) {
      this.broadCastWebsocketLister.unsubscribe();
    }
    if (this.exitBroadcastActionListener) {
      this.exitBroadcastActionListener.unsubscribe();
    }
    if (this.websocketConnectionListener) {
      this.websocketConnectionListener.unsubscribe();
    }
    if (this.customerCallsSubscription) {
      this.customerCallsSubscription.unsubscribe();
    }
    if (this.chatBoxButtonSubscription) {
      this.chatBoxButtonSubscription.unsubscribe();
    }
    if (this.localMessageSubscription) {
      this.localMessageSubscription.unsubscribe();
    }
    this.kayoolMeetService.resetAll();
  }

  async onStartCallRequestReceived(config: BroadcastConfig) {
    console.log('*** onStartCallRequestReceived() ***');

    this.conference = null;
    this.myJitsiID = '';
    this.connectingWithManager = false;
    this.isJoined = false;
    this.disconnected = false;
    this.startWithVideoMuted = false;
    this.showNotAvailableDialog = false;
    this.iamBroadcaster = true;
    this.roomName = config.room;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.avatarUrl = config.avatarUrl;
    this.kollegenetUserID = config.kollegenetUserID;
    this.rejoinAsBroadcaster = config.rejoinAsBroadcaster;
    this.startVideoCall = config.call;
    this.startWithVideoMuted = config.startWithVideoMuted;
    this.callConnected = false;
    this.config = config;
    if (config.ring) {
      this.startRing();
    }
    this.kayoolMeetService.setRoomStateListener(ConnectionState.Connecting);
    if (!config.call) {
      // show chat box
      this.livekitChatService.showChatbox({
        showHeader: true,
        show: true,
      });
    }

    // livekit start
    let roomName = config.room;
    let username = config.firstName + ' ' + config.lastName;
    this.generateTokenAndConnect(roomName, username);
    // livekit end
    this.onBroadcast.emit(true);
  }

  /**get Screen Size */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  showExitOrStopAlert(msg: string) {
    const dialogRef = this.dialog.open(StopAlertComponent, {
      panelClass: 'custom-dialog-container',
      data: msg,
      disableClose: true,
      autoFocus: true,
      restoreFocus: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (dialogRef.componentInstance.status == 'endForAll') {
        // stop session
        this.exitSession = false;
        this.stopBroadcast(false);
      } else if (dialogRef.componentInstance.status == 'Leave') {
        // exit from session
        this.exitSession = true;
        this.stopBroadcast(false);
      }
    });
  }

  stopBroadcastBtnClick() {
    this.stopRinger(true);

    //user is not host so
    // exit from session
    this.exitSession = true;
    this.stopBroadcast(false);
  }

  /**
   *stop Broadcast
   */
  stopBroadcast(autoReconnectEnabled: boolean = false) {
    if (!autoReconnectEnabled) {
      this.broadcastService.autoReconnectChannel = null;
    }
    try {
      if (this.room) {
        this.room.disconnect();
      }
      clearInterval(this.timer);
    } catch (error) {
      // console.log("broadcaster stopBroadcast error");
      console.error(error);
      this.disconnect();
    }
  }

  /**
   * This function is called when we disconnect.
   */
  disconnect() {
    console.log('disconnect!');
    this.stopRinger(true);
    // this.liveStreamRecordingService.stopRecording();
    this.isJoined = false;
    this.disconnected = true;
    this.startVideoCall = false;
    this.onBroadcast.emit(false);
    if (this.room) {
      this.room
        .off(RoomEvent.TrackSubscribed, this.handleTrackSubscribed.bind(this))
        .off(
          RoomEvent.TrackUnsubscribed,
          this.handleTrackUnsubscribed.bind(this)
        )
        .off(
          RoomEvent.ActiveSpeakersChanged,
          this.handleActiveSpeakerChange.bind(this)
        )
        .off(RoomEvent.Disconnected, this.handleDisconnect.bind(this))
        .off(
          RoomEvent.LocalTrackUnpublished,
          this.handleLocalTrackUnpublished.bind(this)
        )
        .off(
          RoomEvent.ParticipantConnected,
          this.handleParticipantConnected.bind(this)
        )
        .off(
          RoomEvent.ParticipantDisconnected,
          this.handleParticipantDisconnected.bind(this)
        )
        .off(RoomEvent.ChatMessage, this.handleChatMessage.bind(this))
        .off(RoomEvent.Reconnecting, this.handleReconnectingRoom.bind(this))
        .off(RoomEvent.Reconnected, this.handleRoomReconnected.bind(this))
        .off(
          RoomEvent.MediaDevicesError,
          this.handleMediaDeviceError.bind(this)
        )
        .off(
          RoomEvent.ConnectionQualityChanged,
          this.handleConnectionQualityChangedEvent.bind(this)
        )
        .off(
          RoomEvent.SignalConnected,
          this.handleSignalConnectedEvent.bind(this)
        )
        .off(
          RoomEvent.TranscriptionReceived,
          this.handleTranscriptions.bind(this)
        )
        .off(RoomEvent.DataReceived, this.onRoomDataReceived.bind(this))
        .off(
          RoomEvent.ConnectionStateChanged,
          this.onRoomConnectionStatusChanged.bind(this)
        );

      this.removeRPCListener();
    }
    if (this.exitSession) {
      //call participant left api if user is not host or user is host and session started by other user
      this.exitFromLiveClassEntryApi();
    } else {
      //call stop broadcast api
      this.onStopBroadcast();
    }
    // to call stop call api
    this.kayoolMeetService.triggerSessionStop(this.showNotAvailableDialog);
    if (this.iamBroadcaster) {
      this.broadcastService.onStopBroadcast();
    } else {
      this.broadcastService.onStopViewingBroadcast();
    }

    this.kayoolMeetService.resetAll();
    this.livekitChatService.reset();
  }

  /**
   * notifiy all channel subscribers that broadcast stopped
   */
  onStopBroadcast() {}

  /**lefting participants details for reporting */
  exitFromLiveClassEntryApi() {}

  exitFromHostApi() {}

  mouseoverEvent() {
    this.changeMousePoint = true;
    if (this.controllsTimeout) {
      clearTimeout(this.controllsTimeout);
    }
    this.controllsTimeout = setTimeout(() => {
      this.changeMousePoint = false;
    }, 10000);
  }

  settimer(minute) {
    //  let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;
      this.wait_for_host_timer = `${prefix}${Math.floor(
        seconds / 60
      )}:${textSec}`;
      if (seconds == 0) {
        clearInterval(this.timer);
      }
      if (!this.broadcastService.waitForHost) {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  isliveClassHost(participant: any) {
    // let liveClassHosts = this.channelDetails?.liveClassHosts;
    // for (let i = 0; i < liveClassHosts?.length; i++) {
    //   if (Number(participant?.kollegenetUser) == liveClassHosts[i]?.userID) {
    //     return true;
    //   }
    // }
    return false;
  }

  onClickSettings() {
    const dialogRef = this.dialog.open(
      KmeetDeviceSettingsComponent
      // {
      //   panelClass: "custom-dialog-container",
      //   data: {
      //     clsID: this.channelDetails.id
      //   },
      // }
    );
  }

  toogleFooterButtonsVisibility() {
    this.showFooterControls = !this.showFooterControls;
  }

  getView() {
    return this.kayoolMeetService.view;
  }

  isWaitForHost() {
    return this.broadcastService.waitForHost;
  }

  checkCallAccepted() {
    if (!this.ringing) return;
    this.websocketService
      .getStatusOfACall(this.roomName)
      .then((res: CallStatus) => {
        if (res?.answered) {
          this.stopRinger(true);
        } else {
          setTimeout(() => {
            this.checkCallAccepted();
          }, 1000);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          this.checkCallAccepted();
        }, 1000);
        // console.error(err);
      });
  }

  startRing() {
    this.ringing = true;
    this.ringer.nativeElement.currentTime = 0;
    this.ringer.nativeElement.volume = 0.3;
    this.ringer.nativeElement.play();

    this.checkCallAccepted();
    this._count = 60;
    this.updateTimer();
  }

  stopRinger(force: boolean = false) {
    console.log('**** STOP RINGER called ****', force);
    const toastLiveExample = document.getElementById('liveToast');
    if (toastLiveExample) {
      toastLiveExample.style.display = 'none';
    }
    if (force) {
      clearInterval(this._interval);
      this.ringing = false;
      this.ringer.nativeElement.pause();
    } else {
      if (this.participants.length > 1) {
        clearInterval(this._interval);
        this.ringing = false;
        this.ringer.nativeElement.pause();
        this.connectingWithManager = false;
        this.callConnected = true;
      }
    }
  }

  onRingerEnded(event) {
    console.log('ringer stoped');
    this.showNotAvailableDialog = true;
    this.stopBroadcastBtnClick();
  }

  updateTimer() {
    console.log('**** UPDATE TIMER CALLED ****');

    this._interval = setInterval(() => {
      if (this._count == 0) {
        console.log('STOP RINGER');
        // clearInterval(this._interval);
        this.stopBroadcast(false);
      }
      this._count--;
    }, 1000);
  }

  // livekit functions
  generateTokenAndConnect(roomName: string, username: string) {
    console.log('*** generateTokenAndConnect() ***');

    this.kayoolMeetService
      .generateToken(
        username,
        roomName,
        this.websocketService.userID,
        this.broadcastService.broadcastConfig.clientID
      )
      .subscribe(
        (res: LivekitAccessTokenRes) => {
          // console.log(res);
          this.token = res.token;
          this.serverURL = res.serverURL;
          this.connect();
        },
        (err: HttpErrorResponse) => {
          console.error(err);
          if (this.mounted) {
            setTimeout(() => {
              this.generateTokenAndConnect(roomName, username);
            }, 4000);
          }
        }
      );
  }

  async connect() {
    this.showCallForwardingMessage = false;
    let agentAudio: HTMLMediaElement = document.getElementById(
      'agentAudio'
    ) as HTMLMediaElement;
    agentAudio.play();

    this.room = new Room({
      // automatically manage subscribed video quality
      // adaptiveStream: true,

      // optimize publishing bandwidth and CPU for published tracks
      dynacast: true,

      // default capture settings
      videoCaptureDefaults: {
        resolution: VideoPresets.h360.resolution,
        facingMode: 'user',
      },
    });
    this.startTime = Date.now();

    // pre-warm connection, this can be called as early as your page is loaded
    this.room.prepareConnection(this.serverURL, this.token);

    // set up event listeners
    this.room
      .on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed.bind(this))
      .on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed.bind(this))
      .on(
        RoomEvent.ActiveSpeakersChanged,
        this.handleActiveSpeakerChange.bind(this)
      )
      .on(RoomEvent.Disconnected, this.handleDisconnect.bind(this))
      .on(
        RoomEvent.LocalTrackUnpublished,
        this.handleLocalTrackUnpublished.bind(this)
      )
      .on(
        RoomEvent.ParticipantConnected,
        this.handleParticipantConnected.bind(this)
      )
      .on(
        RoomEvent.ParticipantDisconnected,
        this.handleParticipantDisconnected.bind(this)
      )
      .on(RoomEvent.ChatMessage, this.handleChatMessage.bind(this))
      .on(RoomEvent.Reconnecting, this.handleReconnectingRoom.bind(this))
      .on(RoomEvent.Reconnected, this.handleRoomReconnected.bind(this))
      .on(RoomEvent.MediaDevicesError, this.handleMediaDeviceError.bind(this))
      .on(
        RoomEvent.ConnectionQualityChanged,
        this.handleConnectionQualityChangedEvent.bind(this)
      )
      .on(RoomEvent.SignalConnected, this.handleSignalConnectedEvent.bind(this))
      .on(RoomEvent.TranscriptionReceived, this.handleTranscriptions.bind(this))
      .on(RoomEvent.DataReceived, this.onRoomDataReceived.bind(this))
      .on(
        RoomEvent.ConnectionStateChanged,
        this.onRoomConnectionStatusChanged.bind(this)
      );

    // connect to room
    await this.room.connect(this.serverURL, this.token, {
      autoSubscribe: true,
    });
    console.log(
      'room connected',
      this.room.name,
      'myID',
      this.room.localParticipant.sid
    );

    // publish local camera and mic tracks
    // await this.room.localParticipant.enableCameraAndMicrophone();
    this.room.localParticipant.name = this.firstName + ' ' + this.lastName;
    this.kayoolMeetService.room = this.room;
    if (this.config.call) {
      try {
        await this.room.localParticipant.setMicrophoneEnabled(true);
      } catch (error) {
        console.error(error);
      }
      // don't turn on camera by default
      // try {
      //   await this.room.localParticipant.setCameraEnabled(true);
      // } catch (error) {
      //   console.error(error);
      // }
    }
    this.initParticipantsArray();
    this.listenForRPC();
  }

  // initialize participants array by adding localparticipant and all other existing remote participants in room
  initParticipantsArray() {
    let remoteParticipants: Participant[] = [];
    this.room.remoteParticipants.forEach((value, key) => {
      remoteParticipants.push(value);
    });
    this.participants = [this.room.localParticipant, ...remoteParticipants];
    console.log(this.participants);
    this.setMainParticipantVideoAsMainVideo();
  }

  listenForRPC() {
    if (!this.room?.localParticipant) return;
    // get active call details
    this.room.localParticipant.registerRpcMethod(
      'getCallDetails',
      this.getCallDetailsRPC.bind(this)
    );
    // session stop function
    this.room.localParticipant.registerRpcMethod(
      'stopSession',
      (data: RpcInvocationData) => {
        return new Promise((resolve, reject) => {
          console.log('stop session event RPC ***');

          this.stopBroadcastBtnClick();
          resolve(JSON.stringify({ message: 'Ok' }));
        });
      }
    );

    this.room.localParticipant.registerRpcMethod(
      'showCallbackForm',
      (data: RpcInvocationData) => {
        return new Promise((resolve, reject) => {
          console.log('stop session event RPC ***');
          this.kayoolMeetService.triggerRequestCallbackForm();
          resolve(JSON.stringify({ message: 'Ok' }));
        });
      }
    );
  }

  removeRPCListener() {
    this.room.localParticipant.unregisterRpcMethod('getCallDetails');
    this.room.localParticipant.unregisterRpcMethod('stopSession');
    this.room.localParticipant.unregisterRpcMethod('showCallbackForm');
  }

  getCallDetailsRPC(data: RpcInvocationData) {
    return new Promise((resolve, reject) => {
      console.log('**** getCallDetailsRPC');
      resolve(
        JSON.stringify({
          name: this.firstName,
          department: this.broadcastService.broadcastConfig.departmentID,
          enterpriseID: this.broadcastService.broadcastConfig.clientID,
          user: this.websocketService.userID,
          callID: this.roomName,
          call: this.config.call,
        })
      );
    });
  }

  handleConnectionQualityChangedEvent(
    quality: ConnectionQuality,
    participant?: Participant
  ) {
    // console.log('connection quality changed', participant?.sid, quality);
  }

  handleSignalConnectedEvent() {
    const signalConnectionTime = Date.now() - this.startTime;
    console.log(`signal connection established in ${signalConnectionTime}ms`);
    // speed up publishing by starting to publish before it's fully connected
    // publishing is accepted as soon as signal connection has established

    // await this.room.localParticipant.enableCameraAndMicrophone();
    // await this.room.localParticipant.setMicrophoneEnabled(true);
    console.log(`tracks published in ${Date.now() - this.startTime}ms`);
  }

  handleMediaDeviceError(e: Error) {
    const failure = MediaDeviceFailure.getFailure(e);
    console.log('media device failure', failure);
  }

  async handleRoomReconnected() {
    console.log(
      'Successfully reconnected. server',
      await this.room.engine.getConnectedServerAddress()
    );
  }

  handleReconnectingRoom() {
    console.log('Reconnecting to room');
  }

  handleParticipantConnected(participant: Participant) {
    console.log('*** handleParticipantConnected() ***');

    console.log(
      'participant',
      participant.identity,
      'sid',
      participant.sid,
      'connected',
      participant.metadata
    );
    if (participant.identity.includes('agent')) {
      participant.name = 'Agent';
    }
    this.participants.push(participant);
    this.setAsMainVideo(this.mainVideoTrack, participant);
    this.stopRinger();
    this.isJoined = true;
    this.showCallForwardingMessage = true;
    if (!this.identifyAgent(participant)) {
    }

    // if mainvideo not present or if mainvideo is from localparticipant then switch to remote participant video
    // if (
    //   this.mainVideoParticipant == null ||
    //   (this.mainVideoParticipant != null &&
    //     this.mainVideoParticipant?.sid === this.room?.localParticipant.sid)
    // ) {
    //   console.log('focusing to remote participant');
    //   this.setFirstRemoteParticipantVideo();
    // }
  }

  identifyAgent(participant: Participant) {
    if (participant.kind === ParticipantKind.AGENT) {
      this.agent = participant;
      // participant
      //   .on(ParticipantEvent.TrackMuted, (pub: TrackPublication) => {
      //     console.log('agent was muted', pub.trackSid, participant.identity);
      //     // renderParticipant(participant);
      //   })
      //   .on(ParticipantEvent.TrackUnmuted, (pub: TrackPublication) => {
      //     console.log('agent was unmuted', pub.trackSid, participant.identity);
      //     // renderParticipant(participant);
      //   })
      //   .on(ParticipantEvent.IsSpeakingChanged, (speak) => {
      //     // renderParticipant(participant);
      //     console.log('agent', participant.identity, 'speaking', speak);
      //   })
      //   .on(ParticipantEvent.ConnectionQualityChanged, () => {
      //     // renderParticipant(participant);
      //   });
    }
    return false;
  }

  handleParticipantDisconnected(participant: RemoteParticipant) {
    console.log(
      'participant',
      participant.identity,
      'sid',
      participant.sid,
      'disconnected',
      participant.metadata
    );
    let index = this.participants.findIndex((p) => p.sid === participant.sid);
    if (index > -1) {
      this.participants.splice(index, 1);
    }

    // switch video to another person
    if (
      this.mainVideoParticipant != null &&
      this.mainVideoParticipant?.sid === participant.sid
    ) {
      console.log('existing main video removed setting new video');
      this.setFirstRemoteParticipantVideo();
    }
  }

  handleTrackSubscribed(
    track: RemoteTrack,
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) {
    if (track.kind === Track.Kind.Audio) {
      // attach it to a new HTMLVideoElement or HTMLAudioElement
      // console.log(`remote track received ${participant?.identity}`);
      const element = track.attach();
      // console.log(element);
      let parentElement = document.getElementById('remoteTracksBroadcaster');
      if (parentElement) parentElement.appendChild(element);
      // Or attach to existing element
      // let agentAudio: HTMLMediaElement = document.getElementById(
      //   'agentAudio'
      // ) as HTMLMediaElement;
      // track.attach(agentAudio);
      // for audio levels
      // this.initializeAudioContextAnalyzerAndRecorder(track.mediaStream);
      // this.interval = setInterval(this.analyzeAudio.bind(this), 32);
    }
    if (track.kind === Track.Kind.Video) {
      // if mainvideo not present or if mainvideo is from localparticipant then switch to remote participant video
      if (
        this.mainVideoParticipant == null ||
        (this.mainVideoParticipant != null &&
          (this.mainVideoParticipant?.sid === this.room?.localParticipant.sid ||
            (this.mainVideoParticipant.sid === participant.sid &&
              !this.mainVideoTrack)) &&
          track.source != Track.Source.ScreenShare)
      ) {
        console.log('focusing to remote participant');

        // if mainvideo not present or if mainvideo is from localparticipant then switch to remote participant video
        this.setAsMainVideo(track, participant);
      }

      if (track.source === Track.Source.ScreenShare) {
        console.log('focusing screen share track');

        this.setAsMainVideo(track, participant);
      }
    }
  }

  handleTrackUnsubscribed(
    track: RemoteTrack,
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) {
    // remove tracks from all attached elements
    track.detach();

    // focus to another video
    if (track.kind === Track.Kind.Video) {
      // if removed video track is showing as main video then switch to another participant video
      if (
        this.mainVideoParticipant != null &&
        this.mainVideoParticipant?.sid === participant.sid
      ) {
        console.log('existing main video removed setting new video');
        this.setFirstRemoteParticipantVideo();
      }
    }
  }

  setFirstRemoteParticipantVideo() {
    for (const [key, remoteParticipant] of this.room.remoteParticipants) {
      let newVideoTrack = remoteParticipant.getTrackPublication(
        Track.Source.Camera
      )?.track;
      this.setAsMainVideo(newVideoTrack, remoteParticipant);
      break;
    }
    if (this.room.remoteParticipants.size === 0) {
      for (const [key, videoTrackPublication] of this.room.localParticipant
        .videoTrackPublications) {
        this.room.localParticipant.videoTrackPublications;
        this.setAsMainVideo(
          videoTrackPublication.track,
          this.room.localParticipant
        );
        break;
      }
    }
  }

  setAsMainVideo(track: Track, participant: Participant) {
    this.mainVideoParticipant = participant;
    this.mainVideoTrack = track;
    this.kayoolMeetService.triggerMainVideo({
      track,
      participant: participant,
    });
  }

  initializeAudioContextAnalyzerAndRecorder(stream: MediaStream) {
    this.audioStream = stream;
    this.audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    this.analyser = this.audioContext.createAnalyser();
    this.source = this.audioContext.createMediaStreamSource(this.audioStream);
    this.source.connect(this.analyser);

    this.analyser.fftSize = 256;
  }

  analyzeAudio() {
    if (this.room && this.room?.state != ConnectionState.Connected) {
      return;
    }
    // console.log('analyzeAudio()');
    const dataArray = new Uint8Array(this.analyser.fftSize);
    this.analyser.getByteTimeDomainData(dataArray);

    // Calculate the average amplitude of the audio signal
    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      sum += Math.abs(dataArray[i] - 128); // Centering around 128
    }
    const averageAmplitude = sum / dataArray.length;
    if (averageAmplitude >= 1)
      console.log('averageAmplitude', averageAmplitude);

    // grapgh 2
    const options = {
      bands: 7,
      loPass: 1,
      hiPass: 50,
    };
    let frequencies: Float32Array = new Float32Array(dataArray.length);
    for (let i = 0; i < dataArray.length; i++) {
      frequencies[i] = dataArray[i];
    }
    frequencies = frequencies.slice(options.loPass, options.hiPass);
    const minHeight = 10;
    const maxHeight = 100;
    const normalizedFrequencies = normalizeFrequencies(frequencies); // is this needed ?
    const chunkSize = Math.ceil(normalizedFrequencies.length / options.bands); // we want logarithmic chunking here
    const chunks: Array<string> = [];
    for (let i = 0; i < options.bands; i++) {
      const summedVolumes = normalizedFrequencies
        .slice(i * chunkSize, (i + 1) * chunkSize)
        .reduce((acc, val) => (acc += val), 0);
      let volume = summedVolumes / chunkSize;
      chunks.push(Math.max(minHeight, volume * 100 + 5) + '%');
    }
    // console.log(chunks);

    this.voiceBarProgress = chunks;

    // graph 2
    this.drawVoiceLevel(averageAmplitude);
  }

  handleLocalTrackUnpublished(
    publication: LocalTrackPublication,
    participant: LocalParticipant
  ) {
    // when local tracks are ended, update UI to remove them from rendering
    publication.track.detach();
  }

  drawVoiceLevel(amplitude: number) {
    let circle1 = document.getElementById('circle1');
    let circle2 = document.getElementById('circle2');
    let circle3 = document.getElementById('circle3');
    if (amplitude >= 5) {
      circle1.setAttribute('stroke', 'rgba(231, 0, 0, 1)');
    } else {
      circle1.setAttribute('stroke', 'rgba(231, 231, 231, 1)');
    }
    if (amplitude > 10) {
      circle2.setAttribute('stroke', 'rgba(231, 0, 0, 1)');
    } else {
      circle2.setAttribute('stroke', 'rgba(231, 231, 231, 1)');
    }
    if (amplitude > 15) {
      circle3.setAttribute('stroke', 'rgba(231, 0, 0, 1)');
    } else {
      circle3.setAttribute('stroke', 'rgba(231, 231, 231, 1)');
    }
    // if (amplitude > 28) {
    // circle4.setAttribute("stroke", "rgba(253, 210, 196, 1)");
    // } else {
    // circle4.setAttribute("stroke", "rgba(231, 231, 231, 1)");
    // }
  }

  handleActiveSpeakerChange(speakers: Participant[]) {
    // show UI indicators when participant is speaking
    // speakers.forEach((p) => {
    //   if (p.sid == this.agent?.sid) {
    //     let averageAmplitude = p.audioLevel * 100;
    //     console.log('agent ', p.sid, averageAmplitude);
    //   } else {
    //     console.log('speaker ', p.sid, p.audioLevel * 100);
    //   }
    // });
  }

  handleDisconnect(reason: DisconnectReason) {
    console.log('disconnected from room');
    if (
      reason == DisconnectReason.ROOM_DELETED ||
      reason == DisconnectReason.SIGNAL_CLOSE ||
      reason == DisconnectReason.JOIN_FAILURE
    ) {
      const dialogRef = this.dialog.open(ExitSessionInformationComponent, {
        data: {},
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.disconnect();
      });
    } else {
      this.disconnect();
    }
  }

  onSendMessage() {
    this.room.localParticipant
      .sendChatMessage(this.chatMessageInput)
      .then((res) => {
        console.log('onSendMessage', res);
      })
      .catch((err) => console.error(err));
    this.chatMessageInput = '';
  }

  isRemoteVideoMuted(track: Track) {
    if (track) return track?.isMuted;
    return true;
  }

  // check local track
  isMicMuted() {
    let trackPublication: LocalTrackPublication =
      this.room?.localParticipant?.getTrackPublication(Track.Source.Microphone);
    if (trackPublication) {
      return trackPublication?.track?.isMuted;
    }
    return true;
  }

  async onToggleMic() {
    if (this.micProcessing || !this.room) return;
    this.micProcessing = true;
    let trackPublication: LocalTrackPublication =
      this.room.localParticipant.getTrackPublication(Track.Source.Microphone);
    if (trackPublication) {
      if (trackPublication.track.isMuted) {
        trackPublication.track.unmute();
      } else {
        trackPublication.track.mute();
      }
    } else {
      try {
        // enable mic
        await this.room.localParticipant.setMicrophoneEnabled(true);
      } catch (error) {
        console.error(error);
      }
    }
    this.micProcessing = false;
  }

  onExpandParticipant(participant: Participant, track: Track) {
    this.setAsMainVideo(track, participant);
  }

  async onToggleVideo() {
    if (this.cameraProcessing) return;
    this.cameraProcessing = true;
    let trackPublication: LocalTrackPublication =
      this.room.localParticipant.getTrackPublication(Track.Source.Camera);
    if (trackPublication) {
      if (trackPublication.track.isMuted) {
        trackPublication.track.unmute();
      } else {
        trackPublication.track.mute();
      }
    } else {
      // enable camera
      try {
        await this.room.localParticipant.setCameraEnabled(true);
        if (this.mainVideoParticipant == null) {
          this.setMainParticipantVideoAsMainVideo();
        }
      } catch (error) {
        console.error(error);
      }
    }
    this.cameraProcessing = false;
  }

  async onToggleScreenshare() {
    if (this.screenShareProcessing) return;
    this.screenShareProcessing = true;
    if (this.room?.localParticipant?.isScreenShareEnabled) {
      try {
        await this.room.localParticipant.setScreenShareEnabled(false);
        this.screenSharing = false;
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await this.room.localParticipant.setScreenShareEnabled(true, {
          resolution: VideoPresets.h1080.resolution,
          contentHint: 'detail',
        });
        this.screenSharing = true;
      } catch (error) {
        console.error(error);
      }
    }
    this.screenShareProcessing = false;
  }

  setMainParticipantVideoAsMainVideo() {
    this.room.localParticipant.videoTrackPublications.forEach(
      (value: LocalTrackPublication, key) => {
        if (this.mainVideoParticipant == null) {
          this.setAsMainVideo(value.track, this.room.localParticipant);
        }
      }
    );
  }

  isVideoMuted() {
    let trackPublication: LocalTrackPublication =
      this.room?.localParticipant?.getTrackPublication(Track.Source.Camera);
    if (trackPublication) {
      return trackPublication?.track?.isMuted;
    }
    return true;
  }

  handleTranscriptions(
    segments: TranscriptionSegment[],
    participant: Participant,
    publication: TrackPublication
  ) {
    // console.log('handleTranscriptions');
    // console.log(segments);
    segments.forEach((s) =>
      this.livekitChatService.setTranscripts(
        s.id,
        segmentToChatMessage(
          s,
          this.livekitChatService.getTranscripts(s.id),
          participant
        )
      )
    );

    this.livekitChatService.updateChatMessages();
  }

  handleChatMessage(
    msg: ChatMessage,
    participant?: LocalParticipant | RemoteParticipant | Participant
  ) {
    // console.log('new message');
    // (<HTMLTextAreaElement>$('chat')).value +=
    // console.log(
    //   `${participant?.identity}${
    //     participant instanceof LocalParticipant ? ' (me)' : ''
    //   }: ${msg.message}\n`
    // );
    const isAgent = participant?.identity === this.agent?.identity;
    const isSelf =
      participant?.identity === this.room?.localParticipant?.identity;
    let name = participant?.name;
    if (!name) {
      if (isAgent) {
        name = 'Agent';
      } else if (isSelf) {
        name = 'You';
      } else {
        name = 'Unknown';
      }
    }
    let message: ChatMessageType = {
      id: msg.id,
      name,
      message: msg.message,
      timestamp: msg.timestamp,
      isSelf: isSelf,
    };
    this.livekitChatService.addMessage(message);
  }

  sendLocalMessage(text: string) {
    this.room.localParticipant
      .sendChatMessage(text)
      .then((chatMessage: ChatMessage) => {
        const encodedLegacyMsg = encode({
          ...chatMessage,
          ignore: serverSupportsChatApi(this.room),
        });
        this.room.localParticipant.publishData(encodedLegacyMsg, {
          reliable: true,
          topic: 'lk-chat-topic',
        });
      });
  }

  onClickChatboxButton() {
    this.livekitChatService.showChatbox({
      ...this.chatboxOptions,
      show: !this.chatboxOptions.show,
    });
  }

  onRoomDataReceived(
    payload: Uint8Array,
    participant?: RemoteParticipant,
    kind?: DataPacket_Kind,
    topic?: string
  ) {
    console.log('RoomEvent.DataReceived', topic);
    switch (topic) {
      case 'agentChatMessage':
        let agentMessageReply: ChatMessage = decode(payload);
        this.handleChatMessage(agentMessageReply, this.agent);
        break;

      default:
        break;
    }
  }

  onRoomConnectionStatusChanged(state: ConnectionState) {
    this.kayoolMeetService.setRoomStateListener(state);
  }
}
