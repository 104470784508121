import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-exit-session-information',
  templateUrl: './exit-session-information.component.html',
  styleUrls: ['./exit-session-information.component.css'],
})
export class ExitSessionInformationComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ExitSessionInformationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {}

  ngOnInit(): void {}

  onClickUnderstoodButton() {
    this.dialogRef.close();
  }
}
