import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Participant,
  RemoteParticipant,
  Room,
  RoomEvent,
} from 'livekit-client';

@Component({
  selector: 'app-km-vertical-participant-view',
  templateUrl: './km-vertical-participant-view.component.html',
  styleUrls: ['./km-vertical-participant-view.component.css'],
})
export class KmVerticalParticipantViewComponent implements OnInit, OnDestroy {
  @Input() room: Room = null;
  participants: Participant[] = [];

  constructor() {}

  ngOnInit(): void {
    this.participants = [
      ...[this.room.localParticipant],
      ...this.room.remoteParticipants.values(),
    ];
    this.room
      .on(
        RoomEvent.ParticipantConnected,
        this.handleParticipantConnected.bind(this)
      )
      .on(
        RoomEvent.ParticipantDisconnected,
        this.handleParticipantDisconnected.bind(this)
      );
  }

  ngOnDestroy(): void {
    this.room
      .off(
        RoomEvent.ParticipantConnected,
        this.handleParticipantConnected.bind(this)
      )
      .off(
        RoomEvent.ParticipantDisconnected,
        this.handleParticipantDisconnected.bind(this)
      );
  }

  handleParticipantConnected(participant: Participant) {
    console.log('*** handleParticipantConnected() ***');

    console.log(
      'participant',
      participant.identity,
      'sid',
      participant.sid,
      'connected',
      participant.metadata
    );
    if (participant.identity.includes('agent')) {
      participant.name = 'Agent';
    }
    this.participants.push(participant);
  }

  handleParticipantDisconnected(participant: RemoteParticipant) {
    console.log(
      'participant',
      participant.identity,
      'sid',
      participant.sid,
      'disconnected',
      participant.metadata
    );
    let index = this.participants.findIndex((p) => p.sid === participant.sid);
    if (index > -1) {
      this.participants.splice(index, 1);
    }
  }
}
