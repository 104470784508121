import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LivekitChatService } from '../../../service/livekit-chat.service';
import { Subscription } from 'rxjs';
import { ChatMessageType } from '../../../model/ChatMessageType';
import { ChatBoxOptions } from '../../../model/ChatBoxOptions';
import { ConnectionState } from 'livekit-client';
import { KayoolMeetService } from '../../../service/kayool-meet.service';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.css'],
})
export class ChatWindowComponent implements OnInit, OnDestroy {
  chatBoxOptions: ChatBoxOptions = { show: false, showHeader: true };
  messages: ChatMessageType[] = [];
  message: string = '';
  chatBoxHeight: any = null;
  msgSendingLoader: boolean = false;
  msgRenderingLoader: boolean = false;
  scrollTop: number = 0;
  divHight: number = 0;
  connectionState: ConnectionState = ConnectionState.Connecting;
  chatBoxButtonSubscription: Subscription;
  messageSubscription: Subscription;
  roomStatusSubscription: Subscription;

  constructor(
    private livekitChatService: LivekitChatService,
    private kayoolMeetService: KayoolMeetService
  ) {}

  ngOnInit(): void {
    this.adjustDialogHeight();
    this.chatBoxButtonSubscription = this.livekitChatService
      .getChatBoxPreviewObservable()
      .subscribe((options: ChatBoxOptions) => {
        this.chatBoxOptions = options;
      });

    this.messageSubscription = this.livekitChatService
      .getMessageObservable()
      .subscribe((msgs: ChatMessageType[]) => {
        this.messages = msgs;
        setTimeout(() => {
          this.scrollToElementByID(document.getElementById('msgBottom'));
        }, 400);
      });

    this.roomStatusSubscription = this.kayoolMeetService
      .getRoomStateListener()
      .subscribe((state) => {
        this.connectionState = state;
      });
  }

  ngOnDestroy(): void {
    if (this.chatBoxButtonSubscription) {
      this.chatBoxButtonSubscription.unsubscribe();
    }
    if (this.roomStatusSubscription) {
      this.roomStatusSubscription.unsubscribe();
    }
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  onChatBoxCloseBtnClick() {
    this.livekitChatService.showChatbox({
      ...this.chatBoxOptions,
      show: !this.chatBoxOptions.show,
    });
  }

  adjustDialogHeight() {
    // Calculate the available space for the dialog (subtract keyboard height)
    this.chatBoxHeight = `calc(${window.innerHeight}px * 0.80)`;
  }

  @HostListener('scroll', ['$event']) // for window scroll events
  scrollHandler(event) {
    this.setDivHieght();
    this.scrollTop = event.srcElement.scrollTop;
  }

  scrollToElementByID($element): void {
    if ($element) {
      $element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }

  onSendMessage() {
    if (!this.message || this.msgSendingLoader || this.msgRenderingLoader) {
      return;
    }
    // this.msgSendingLoader = true;
    this.livekitChatService.sendLocalMessage(this.message);
    this.message = '';
  }

  generateSessionId() {
    const timestamp = Date.now().toString(36);
    const randomString = Math.random().toString(36).substr(2, 9);
    return timestamp + randomString;
  }

  onComplete() {
    this.msgRenderingLoader = false;
  }

  setDivHieght() {
    let divElement = document.getElementById('getHeight');
    this.divHight = divElement.offsetHeight;
  }
  isScrolling() {
    this.setDivHieght();
    if (this.divHight - 360 <= this.scrollTop) {
      this.scrollToElementByID(document.getElementById('msgBottom'));
    }
  }
}
