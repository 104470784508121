import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActiveCall, ActiveCallsWebsocket } from 'src/app/model/Calls';
import {
  ClientDepartmentRes,
  ClientDetails,
} from 'src/app/model/ClientDetails';
import { KayoolMeetService } from 'src/app/modules/kayool-meet/service/kayool-meet.service';
import { VideoBroadcastService } from 'src/app/modules/kayool-meet/service/video-broadcast.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { VideoCallFormComponent } from '../video-call-form/video-call-form.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  title = 'kayool-meet';
  startVideoCall: boolean = false;
  activeCall: ActiveCall = null;
  client: ClientDetails = null;
  departmentID: string = null;
  showPageLoader: boolean = false;
  clientIdError: boolean = false;
  showCloseButton: boolean = true;
  getClientApiCalling: boolean = false;

  private sessionStopSubscription: Subscription;
  private customerCallsSubscription: Subscription;
  private clientRemovedOrDisabledSubscription: Subscription;
  private paramaMapSubscription: Subscription;
  private requestCallbackFormSubscription: Subscription;

  constructor(
    private videoBroadcastService: VideoBroadcastService,
    private dialog: MatDialog,
    private kayoolMeetService: KayoolMeetService,
    private websocketService: WebsocketService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('call')) {
      // website is loaded from the video call url
      // getting clientId/uniqueName from the address
      this.paramaMapSubscription = this.activatedRoute.paramMap.subscribe(
        (params) => {
          console.log('website is loaded from the video call url');
          this.showCloseButton = false;
          if (this.router.url.includes('/call/u/')) {
            // unique name available for video call url
            this.getClientDeptDetailsByUniqueName(params.get('uname'));
          } else {
            let clientID = params.get('clientId');
            this.departmentID = params.get('deptId');
            this.getEnterpriseClientDetailsById(clientID);
          }
        }
      );
    } else {
      // website is loaded from the script
      console.log('website is loaded from the script');
      let clientID = this.websocketService.getEnterpriseClientData()?.clientID;
      if (clientID) {
        this.departmentID =
          this.websocketService.getEnterpriseClientData()?.deptID;
        this.getEnterpriseClientDetailsById(clientID);
      } else {
        this.showErrorMessage();
      }
    }

    try {
      window.top.postMessage('onLoadIframe', '*');
    } catch (error) {
      console.error('postMessage Error', error);
    }

    this.customerCallsSubscription = this.websocketService
      .getCustomerCallObservable()
      .subscribe((res: ActiveCallsWebsocket) => {
        if (res.delete) {
          console.log('call stopped by shop');
          if (this.activeCall && this.activeCall.id == res.call.id) {
            //stop call
            this.videoBroadcastService.stopActiveBroadcast();
            this.activeCall = null;
          }
        }
      });

    this.sessionStopSubscription = this.kayoolMeetService
      .getSessionStopObservable()
      .subscribe((showNotAvailableDialog: boolean) => {
        if (this.activeCall) {
          console.log('self stop');
          this.activeCall = null;
          this.websocketService
            .stopMyCall()
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
        }

        if (showNotAvailableDialog) {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Call Disconnected',
              message: `All our customer care executives are very busy.
              You can submit a request to get a callback from our team or please call again after some time.`,
              clientID: this.client.id,
            },
            disableClose: true,
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.closeIframe();
          });
        } else this.closeIframe();
      });

    this.requestCallbackFormSubscription = this.kayoolMeetService
      .getRequestCallbackFormObservable()
      .subscribe((show) => {
        if (show) {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Call Disconnected',
              message: `All our customer care executives are very busy.
              You can submit a request to get a callback from our team or please call again after some time.`,
              clientID: this.client.id,
              showRequestCallBackPage: true,
              callID:this.activeCall?.id
            },
            disableClose: true,
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((result) => {});
        }
      });

    this.clientRemovedOrDisabledSubscription = this.websocketService
      .getClientRemovedOrDisabledObservable()
      .subscribe((res) => {
        this.client = null;
      });
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
    if (this.sessionStopSubscription)
      this.sessionStopSubscription.unsubscribe();

    if (this.customerCallsSubscription)
      this.customerCallsSubscription.unsubscribe();

    if (this.clientRemovedOrDisabledSubscription)
      this.clientRemovedOrDisabledSubscription.unsubscribe();

    if (this.paramaMapSubscription) this.paramaMapSubscription.unsubscribe();
    if (this.requestCallbackFormSubscription)
      this.requestCallbackFormSubscription.unsubscribe();
  }

  getClientDeptDetailsByUniqueName(uName: string) {
    console.log('**** getClientDeptDetailsByUniqueName() called ****', uName);
    this.getClientApiCalling = true;
    this.websocketService
      .getClientDeptDetailsByUniqueNameApi(uName)
      .then((res: ClientDepartmentRes) => {
        if (res.client.active) {
          this.client = res.client;
          this.getClientApiCalling = false;
          this.websocketService.initWebsocketConnection(this.client.id);
          if (res?.department) this.departmentID = res.department.id;
          this.onClickShowViaVideoCall();
        } else {
          this.showErrorMessage();
          return;
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.error.message == 'Not found') {
          this.showErrorMessage();
          return;
        }
        setTimeout(() => {
          this.getClientDeptDetailsByUniqueName(uName);
        }, 4000);
      });
  }

  getEnterpriseClientDetailsById(id: string) {
    console.log('**** getEnterpriseClientDetailsById() ****');
    this.getClientApiCalling = true;
    this.websocketService
      .getClientDetailsByIdApi(id)
      .then((res: ClientDetails) => {
        if (res.active) {
          this.client = res;
          this.getClientApiCalling = false;
          this.websocketService.initWebsocketConnection(this.client.id);
          // show the modal to start call only if the enterprise client id is available
          this.onClickShowViaVideoCall();
        } else {
          this.showErrorMessage();
          return;
        }
      })
      .catch((err) => {
        console.error(err);
        if (
          err.error.message == 'Invalid Client ID' ||
          err.error.message == 'Client not exist'
        ) {
          this.showErrorMessage();
          return;
        }
        setTimeout(() => {
          this.getEnterpriseClientDetailsById(id);
        }, 4000);
      });
  }

  showErrorMessage() {
    this.client = null;
    this.departmentID = null;
    this.clientIdError = true;
    this.showPageLoader = false;
    this.dialog.closeAll();
    this.getClientApiCalling = false;
  }

  closeIframe() {
    try {
      window.top.postMessage('closeKayoolMeet', '*');
    } catch (error) {
      console.error('postMessage Error', error);
    }
    // show the modal to start call only if the enterprise client id is available
    if (this.client != null) this.onClickShowViaVideoCall();
  }

  onBroadcast(start: boolean) {
    this.showPageLoader = false;
    this.startVideoCall = start;
  }

  onClickShowViaVideoCall() {
    console.log('**** onClickShowViaVideoCall() called ****');

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { client: this.client, deptId: this.departmentID };

    const dialogRef = this.dialog.open(VideoCallFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.activeCall = dialogRef.componentInstance.activeCall;
      if (!this.clientIdError)
        this.showPageLoader = !dialogRef.componentInstance.isCancelled;
      this.clientIdError = false;
    });
  }
}
