<div class="form-container">
  <div class="d-flex headerSection">
    <h6
      style="
        display: contents;
        /* font-family: Oswald, sans-serif !important ; */
        font-size: 1rem !important;
        font-weight: 500;
      "
    >
      Enter Your Name
    </h6>

    <div class="closeBtn" (click)="onClose()">
      <ion-icon name="close-outline" style="font-size: 25px"></ion-icon>
    </div>
  </div>

  <div class="form-group">
    <input
      class="form-control"
      type="text"
      name="user_name"
      maxlength="60"
      autocomplete="off"
      [(ngModel)]="userName"
      placeholder="Name"
      (keydown.enter)="onStartVideoCall()"
    />
  </div>

  <!-- show department picker only if ai agent not anabled -->
  <div
    *ngIf="
      !client?.aiAgentConfig?.enabled &&
      !callingDepartmentId &&
      departments.length > 0
    "
    style="
      flex-direction: row;
      align-items: center;
      display: flex;
      margin-top: 15px;
    "
  >
    <div style="flex: 1">
      <label class="form-label fw-bold">Select Department</label>
    </div>
    <div style="flex: 1">
      <select
        class="form-control form-select"
        name="department"
        (change)="selectValue($event)"
      >
        <option
          *ngFor="let dept of departments.body; index as i"
          [value]="dept.id"
        >
          {{ dept?.name }}
        </option>
      </select>
    </div>
  </div>

  <div
    class="d-flex mt-3"
    [ngClass]="{
      'justify-content-between': client?.aiAgentConfig?.enabled,
      'justify-content-end': !client?.aiAgentConfig?.enabled
    }"
  >
    <!-- dont show start chat button if ai agent not enabled -->
    <button
      [disabled]="!userName || userName.length < 3"
      type="submit"
      class="btn btn-primary fw-bolder startChatBtn"
      (click)="onStartChat()"
      *ngIf="client?.aiAgentConfig?.enabled"
    >
      <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
      <span *ngIf="!loading">Start Chat</span>
    </button>

    <button
      [disabled]="!userName || userName.length < 3"
      type="submit"
      class="btn btn-primary fw-bolder"
      (click)="onStartVideoCall()"
      style="letter-spacing: 1px"
    >
      <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
      <span *ngIf="!loading">Start Call</span>
    </button>
  </div>

  <div class="d-flex justify-content-center" style="margin-top: 15px">
    <span
      >Powered By
      <strong
        ><a href="https://connectnow.ai" target="_blank">ConnectNow</a></strong
      ></span
    >
  </div>
</div>
