import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Participant,
  ParticipantEvent,
  Room,
  Track,
  TrackPublication,
} from 'livekit-client';
import { Subscription } from 'rxjs';
import { MainVideoAndParticipant } from '../../../model/MainVideoAndParticipant';
import { KayoolMeetService } from '../../../service/kayool-meet.service';

@Component({
  selector: 'app-km-participant',
  templateUrl: './km-participant.component.html',
  styleUrls: ['./km-participant.component.css'],
})
export class KmParticipantComponent implements OnInit, OnDestroy {
  @Input() participant: Participant = null;
  @Input() room: Room = null;
  trackPublications: TrackPublication[] = [];
  mainVideoSubscription: Subscription;
  mainVideo: MainVideoAndParticipant = null;
  participantAudioTrack: TrackPublication = null;

  constructor(private kayoolMeetService: KayoolMeetService) {}

  ngOnInit(): void {
    this.participant.getTrackPublications().forEach((tp) => {
      if (tp.kind == Track.Kind.Video) {
        this.trackPublications.push(tp);
      }
      if (tp.kind == Track.Kind.Audio) {
        this.participantAudioTrack = tp;
      }
    });

    this.participant.on(
      ParticipantEvent.TrackPublished,
      this.onRemoteTrackPublication.bind(this)
    );
    this.participant.on(
      ParticipantEvent.TrackUnpublished,
      this.onRemoteTrackunPublication.bind(this)
    );
    this.participant.on(
      ParticipantEvent.LocalTrackPublished,
      this.onRemoteTrackPublication.bind(this)
    );
    this.participant.on(
      ParticipantEvent.LocalTrackUnpublished,
      this.onRemoteTrackunPublication.bind(this)
    );

    this.mainVideoSubscription = this.kayoolMeetService
      .getMainVideoObservable()
      .subscribe((res: MainVideoAndParticipant) => {
        this.mainVideo = res;
      });
  }

  ngOnDestroy(): void {
    this.participant.off(
      ParticipantEvent.TrackPublished,
      this.onRemoteTrackPublication.bind(this)
    );
    this.participant.off(
      ParticipantEvent.TrackUnpublished,
      this.onRemoteTrackunPublication.bind(this)
    );
    this.participant.off(
      ParticipantEvent.LocalTrackPublished,
      this.onRemoteTrackPublication.bind(this)
    );
    this.participant.off(
      ParticipantEvent.LocalTrackUnpublished,
      this.onRemoteTrackunPublication.bind(this)
    );
    if (this.mainVideoSubscription) this.mainVideoSubscription.unsubscribe();
  }

  onRemoteTrackPublication(publication: TrackPublication) {
    if (publication.kind == Track.Kind.Video) {
      this.trackPublications.push(publication);
    }
    if (publication.kind == Track.Kind.Audio) {
      this.participantAudioTrack = publication;
    }
  }

  onRemoteTrackunPublication(publication: TrackPublication) {
    let index = this.trackPublications.findIndex(
      (t) => t.trackSid === publication.trackSid
    );
    if (index > -1) {
      this.trackPublications.splice(index, 1);
    }
    if (publication.kind == Track.Kind.Audio) {
      this.participantAudioTrack = null;
    }
  }
}
