<div class="parent">
  <div
    class="chat-container"
    [ngStyle]="{
      height: chatBoxHeight,
      display: chatBoxOptions?.show ? 'flex' : 'none'
    }"
  >
    <!-- header -->
    <div
      class="chat-box-header"
      *ngIf="chatBoxOptions.showHeader; else noHeader"
    >
      <div
        style="margin: 5px 10px"
        class="round-button"
        [ngStyle]="{
          'background-color': 'lightgreen',
          color: '#fff'
        }"
      >
        <svg
          xmlns=" http://www.w3.org/2000/svg "
          height="20px"
          viewBox="0 0 512 512"
          fill="currentColor"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"
          />
        </svg>
      </div>

      <div
        style="
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <h3 class="user-name fw-bolder">Assistant</h3>
        <h6 class="online-status text-secondary">Online</h6>
      </div>
      <div>
        <button
          class="round-button close-button"
          (click)="onChatBoxCloseBtnClick()"
        >
          ✕
        </button>
      </div>
    </div>
    <ng-template #noHeader>
      <div class="chat-box-header" style="height: 15px"></div>
    </ng-template>
    <!-- messages -->
    <div class="chat-box-content">
      <div
        class="message-preview custom_scrollbar"
        (scroll)="scrollHandler($event)"
      >
        <div id="getHeight" class="test-scroll">
          <!-- connecting loader -->
          <div
            *ngIf="connectionState != 'connected' || messages.length < 1"
            class="loaderDiv"
          >
            <span style="font-size: 11px">Connecting...</span>
            <div id="kayool-meet-loader" class="loaderSpinner"></div>
          </div>

          <div
            *ngFor="let msg of messages; let i = index"
            class="message-item"
            [ngClass]="{
              'message-item-right': msg.isSelf,
              'message-item-left': !msg.isSelf
            }"
          >
            <div
              style="font-size: 12px; white-space: pre-line"
              *ngIf="msg.message"
            >
              <span>
                {{ msg.message }}
              </span>

              <span
                class="blink_me"
                *ngIf="msgRenderingLoader && i == messages.length - 1"
              >
                |</span
              >
            </div>

            <div
              *ngIf="!msg.message && msgSendingLoader"
              [ngStyle]="{
                background: '#000'
              }"
              class="loader"
            ></div>
          </div>
          <div id="msgBottom"></div>
        </div>
      </div>

      <div class="message-input-container">
        <input
          class="ps-1"
          type="text"
          (keyup.enter)="onSendMessage()"
          placeholder="Ask something here.."
          [(ngModel)]="message"
          (focus)="adjustDialogHeight()"
          (blur)="adjustDialogHeight()"
        />
        <button
          *ngIf="!msgRenderingLoader && !msgSendingLoader"
          (click)="onSendMessage()"
          class="send-msg-button"
          [ngStyle]="{
            color: !message ? 'gray' : '#000',
            cursor: !message ? 'not-allowed' : 'pointer'
          }"
        >
          <svg
            xmlns=" http://www.w3.org/2000/svg "
            height="20px"
            viewBox="0 0 512 512"
            fill="currentColor"
          >
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
            <path
              d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
            />
          </svg>
        </button>
        <button
          class="send-msg-button"
          *ngIf="msgRenderingLoader || msgSendingLoader"
        >
          <div
            [ngStyle]="{
              background: '#000'
            }"
            class="renderingLoader"
          ></div>
        </button>
      </div>
    </div>
    <!-- footer -->
    <div class="chat-box-footer">
      <b
        >Powered by
        <a href=" https://www.kayool.com " target="_blank">Kayool</a></b
      >
    </div>
  </div>
</div>
