import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChatBoxOptions } from '../model/ChatBoxOptions';
import { ChatMessageType } from '../model/ChatMessageType';

@Injectable({
  providedIn: 'root',
})
export class LivekitChatService {
  private transcripts: Map<string, ChatMessageType> = new Map();
  private chatMessages: Map<string, ChatMessageType> = new Map();
  messages: ChatMessageType[] = [];
  private chatBoxPreviewObserver = new Subject<ChatBoxOptions>();
  private messageObserver = new BehaviorSubject([]);
  private newLocalMessageObserver = new Subject();

  constructor() {}

  addMessage(message: ChatMessageType) {
    this.chatMessages.set(message.id, message);
    this.updateChatMessages();
  }

  setTranscripts(id: string, message: ChatMessageType) {
    this.transcripts.set(id, message);
  }

  getTranscripts(id: string) {
    return this.transcripts.get(id);
  }

  updateChatMessages() {
    let msgs: ChatMessageType[] = [
      ...Array.from(this.chatMessages.values()),
      ...Array.from(this.transcripts.values()),
    ];
    msgs.sort((a, b) => a.timestamp - b.timestamp);
    this.messages = msgs;
    this.messageObserver.next(this.messages);
  }

  getChatBoxPreviewObservable() {
    return this.chatBoxPreviewObserver.asObservable();
  }

  showChatbox(options: ChatBoxOptions) {
    this.chatBoxPreviewObserver.next(options);
  }

  getMessageObservable() {
    return this.messageObserver.asObservable();
  }

  getLocalMessageObservables() {
    return this.newLocalMessageObserver.asObservable();
  }

  sendLocalMessage(msg: string) {
    this.newLocalMessageObserver.next(msg);
  }

  reset() {
    this.showChatbox({ show: false, showHeader: false });
    this.chatMessages = new Map();
    this.transcripts = new Map();
    this.messages = [];
    this.updateChatMessages();
  }
}
